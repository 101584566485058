/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"

import "../assets/css/style.css"
import "../assets/css/responsive.css"
import "../assets/css/vendor.css"

export default function RoomSpacing() {
  let imagealt = "Sogno Casa Liguria"

  return (
    <StaticQuery
      query={graphql`
        query {
          allWpPage(filter: { id: { eq: "cG9zdDoxMTM=" } }) {
            nodes {
              slider {
                slider {
                  slides {
                    ... on WpProprieta {
                      title
                      slug
                      proprieta {
                        citta {
                          ... on WpLocalita {
                            id
                            title
                          }
                        }
                        immagine {
                          sourceUrl
                          localFile {
                            childImageSharp {
                              fluid(maxWidth: 1920, quality: 80) {
                                ...GatsbyImageSharpFluid_withWebp
                              }
                            }
                          }
                        }
                        prezzo
                        slider {
                          id
                          sourceUrl
                          localFile {
                            childImageSharp {
                              fluid(maxWidth: 1920, quality: 80) {
                                ...GatsbyImageSharpFluid_withWebp
                              }
                            }
                          }
                        }
                        sliderContenuto {
                          caratteristiche
                          testoSlider
                          titoloSlider
                        }
                        struttura
                        testoDescrizione
                        tipologia
                        etichetta
                        venduta
                        titoloDescrizione
                        vicinanze
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div className="apartments-area bg-gray pd-top-100 pd-bottom-100">
          <div className="container">
            <div className="section-title text-center text-color-white">
              <h2 className="title">Proposte in evidenza</h2>
            </div>

            <div
              id="carouselExampleFade"
              className="carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner apartments-slider-2">
                {data.allWpPage.nodes[0].slider.slider.slides.map((node, i) => (
                  <div
                    key={i}
                    className={`item carousel-item ${i === 0 ? "active" : ""}`}
                  >
                    <div className="row justify-content-center">
                      <div className="col-xl-6 col-lg-7">
                        <Link to={node.slug}>
                          <div className="thumb">

                          {/* test */}
                          {(function () {
                            if (node.proprieta.etichetta ) {
                              return (
                                <span className="etichetta etichetta-list etichetta-list-big"
                                  dangerouslySetInnerHTML={{
                                    __html: `${node.proprieta.etichetta}`,
                                  }}
                                ></span>
                              )
                            } else {
                              return ""
                            }
                          })()}

                          {(function () {
                            if (node.proprieta.venduta ) {
                              return (
                                <span className="etichetta etichetta-list etichetta-list-big venduta"
                                  dangerouslySetInnerHTML={{
                                    __html: `${node.proprieta.venduta}`,
                                  }}
                                ></span>
                              )
                            } else {
                              return ""
                            }
                          })()}
                          {/* /test */}

                            <Img
                              key={i}
                              fluid={{
                                ...node.proprieta.slider[0].localFile
                                  .childImageSharp.fluid,
                                sizes: "(max-width: 512px) 80vw",
                              }}
                              alt={imagealt}
                            />

                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-6 align-self-end">
                        <div className="details">
                          <span>{i + 1}</span>
                          <h6>{node.proprieta.citta[0].title}</h6>
                          <h2 className="title">{node.title}</h2>
                          <div
                            className="title"
                            dangerouslySetInnerHTML={{
                              __html: `${node.proprieta.testoDescrizione}`,
                            }}
                          ></div>
                          <div className="row">
                            <div className="col-sm-12">
                              <ul className="rld-list-style mb-3 mb-sm-0">
                                {node.proprieta.sliderContenuto.caratteristiche
                                  .slice(0, 4)
                                  .map((caratteristica, i) => (
                                    <li key={i} className="col-sm-6">
                                      <i className="fa fa-check" />{" "}
                                      {caratteristica}
                                    </li>
                                  ))}
                              </ul>
                              <Link
                                className="btn btn-yellow mt-2"
                                to={node.slug}
                              >
                                Dettagli
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <a
                className="carousel-control-prev"
                href="#carouselExampleFade"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#carouselExampleFade"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      )}
    />
  )
}
