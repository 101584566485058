import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Banner from "../components/banner"
import FeaturedProperties from "../components/featured-properties"
import RoomSpacing from "../components/room-spacing"
import Contact from "../components/contact"

export default function Home() {
  return (
    <Layout>
      <SEO title="Home" />
      <Banner />
      <FeaturedProperties />
      <RoomSpacing />
      <Contact />
    </Layout>
  )
}