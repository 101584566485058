import React, { useState } from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

export default function Banner() {
  const [localita, setLocalita] = useState("/tutte-le-localita/")
  return (
    <StaticQuery
      query={graphql`
        query {
          allWpPage(filter: { id: { eq: "cG9zdDoxMTM=" } }) {
            nodes {
              title
              slug
              banner {
                titolo
                immagine {
                  sourceUrl
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              cerca {
                cerca {
                  cerca {
                    ... on WpLocalita {
                      title
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <>
          <BackgroundImage
            className="banner-area jarallax"
            fluid={{
              ...data.allWpPage.nodes[0].banner.immagine.localFile
                .childImageSharp.fluid,
              sizes: "(max-width: 512px) 80vw",
            }}
          >
            <div className="container">
              <div className="banner-inner-wrap">
                <div className="row">
                  <div className="col-12">
                    <div className="banner-inner">
                      <div
                        className="title"
                        dangerouslySetInnerHTML={{
                          __html: `${data.allWpPage.nodes[0].banner.titolo}`,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </BackgroundImage>
          <div className="main-search-area">
            <div className="container">
              <div className="banner-search-wrap">
                <ul className="nav nav-tabs rld-banner-tab">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#localita"
                      style={{ pointerEvents: "none" }}
                    >
                      Trova
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane fade show active" id="localita">
                    <div className="rld-main-search">
                      <div className="row">
                        <div className="col-xl-10 col-lg-8 col-md-8">
                          <div className="rld-single-select">
                            <label
                              className="select-parent"
                              htmlFor="label-select"
                            >
                              <span>Seleziona località:</span>
                              <select
                                id="label-select"
                                className="select single-select"
                                onBlur={e => setLocalita(e.currentTarget.value)}
                              >
                                <option value="/tutte-le-localita/" key={-1}>
                                  Tutte le località
                                </option>
                                {data.allWpPage.nodes[0].cerca.cerca.cerca.map(
                                  (node, i) => (
                                    <option value={"../" + node.slug} key={i}>
                                      {node.title}
                                    </option>
                                  )
                                )}
                              </select>
                            </label>
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-4 col-md-4">
                          <Link className="btn btn-yellow" to={localita}>
                            CERCA
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    />
  )
}
