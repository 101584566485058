import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import logoProposte from "../images/logo-proposte.svg"

export default function FeaturedProperties() {
  let imagealt = "Sogno Casa Liguria"

  return (
    <StaticQuery
      query={graphql`
        query {
          allWpPage(filter: { id: { eq: "cG9zdDoxMTM=" } }) {
            nodes {
              title
              slug
              proposteInEvidenza {
                proposteInEvidenza {
                  proposteInEvidenza {
                    ... on WpAppartamento {
                      id
                      title
                      slug
                      appartamento {
                        citta {
                          ... on WpLocalita {
                            title
                          }
                        }
                        extra
                        prezzo
                        immagine {
                          sourceUrl
                          localFile {
                            childImageSharp {
                              fluid(maxWidth: 1920, quality: 80) {
                                ...GatsbyImageSharpFluid_withWebp
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                propostaGrande {
                  propostaGrande {
                    ... on WpProprieta {
                      title
                      slug
                      proprieta {
                        citta {
                          ... on WpLocalita {
                            id
                            title
                          }
                        }
                        immagine {
                          sourceUrl
                          localFile {
                            childImageSharp {
                              fluid(maxWidth: 1920, quality: 80) {
                                ...GatsbyImageSharpFluid_withWebp
                              }
                            }
                          }
                        }
                        prezzo
                        slider {
                          id
                          sourceUrl
                        }
                        sliderContenuto {
                          caratteristiche
                          testoSlider
                          titoloSlider
                        }
                        struttura
                        testoDescrizione
                        tipologia
                        etichetta
                        venduta
                        titoloDescrizione
                        vicinanze
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <>
          <div className={"featured-area  pd-top-60"}>
            <div className="container">
              <div className="section-title text-center">
                <h2 className="title">Abbiamo selezionato per te</h2>
              </div>
              <div className="row justify-content-left">
                <div className="col-xl-6 col-lg-6">
                  <div className="single-leading-feature">
                    <Link
                      to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[0].slug
                      }
                    >
                      <div className="slf-overlay" />
                      <div className="thumb">

                      {/* test */}
                      {(function () {
                        if (data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[0].proprieta.etichetta ) {
                          return (
                            <span className="etichetta etichetta-list etichetta-list-big"
                              dangerouslySetInnerHTML={{
                                __html: `${data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[0].proprieta.etichetta}`,
                              }}
                            ></span>
                          )
                        } else {
                          return ""
                        }
                      })()}

                      {(function () {
                        if (data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[0].proprieta.venduta ) {
                          return (
                            <span className="etichetta etichetta-list etichetta-list-big venduta"
                              dangerouslySetInnerHTML={{
                                __html: `${data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[0].proprieta.venduta}`,
                              }}
                            ></span>
                          )
                        } else {
                          return ""
                        }
                      })()}
                      {/* /test */}

                        <Img
                          fluid={{
                            ...data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[0].proprieta
                              .immagine.localFile.childImageSharp.fluid,
                            sizes: "(max-width: 512px) 80vw",
                          }}
                          alt={imagealt}
                        />
                      </div>
                      <div className="details">
                        <span>
                          {
                            data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[0].proprieta
                              .citta[0].title
                          }
                        </span>
                        <h4 className="title readeal-top">
                          {
                            data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[0].title
                          }
                        </h4>
                        <span
                          className="tipologia"
                          dangerouslySetInnerHTML={{
                            __html: `${data.allWpPage.nodes[0].proposteInEvidenza.propostaGrande.propostaGrande[0].proprieta.tipologia}`,
                          }}
                        ></span>
                      </div>
                    </Link>
                  </div>
                </div>

                {/* <div className="col-xl-6 col-lg-6">
                  <div className="single-leading-feature">
                    <Link
                      to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[1].slug
                      }
                    >
                      <div className="thumb">
                        <div className="slf-overlay" />
                        <Img
                          fluid={{
                            ...data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[1].proprieta
                              .immagine.localFile.childImageSharp.fluid,
                            sizes: "(max-width: 512px) 80vw",
                          }}
                          alt={imagealt}
                        />
                      </div>

                      <div className="details">
                        <span>
                          {
                            data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[1].proprieta
                              .citta[0].title
                          }
                        </span>
                        <h4 className="title readeal-top">
                          {
                            data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[1].title
                          }
                        </h4>
                        <span
                          className="tipologia"
                          dangerouslySetInnerHTML={{
                            __html: `${data.allWpPage.nodes[0].proposteInEvidenza.propostaGrande.propostaGrande[1].proprieta.tipologia}`,
                          }}
                        ></span>
                      </div>
                    </Link>
                  </div>
                </div> */}

                {/* <div className="col-xl-6 col-lg-6">
                  <div className="single-leading-feature">
                    <Link
                      to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[2].slug
                      }
                    >
                      <div className="thumb">
                        <div className="slf-overlay" />
                        <Img
                          fluid={{
                            ...data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[2].proprieta
                              .immagine.localFile.childImageSharp.fluid,
                            sizes: "(max-width: 512px) 80vw",
                          }}
                          alt={imagealt}
                        />
                      </div>

                      <div className="details">
                        <span>
                          {
                            data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[2].proprieta
                              .citta[0].title
                          }
                        </span>
                        <h4 className="title readeal-top">
                          {
                            data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[2].title
                          }
                        </h4>
                        <span
                          className="tipologia"
                          dangerouslySetInnerHTML={{
                            __html: `${data.allWpPage.nodes[0].proposteInEvidenza.propostaGrande.propostaGrande[2].proprieta.tipologia}`,
                          }}
                        ></span>
                      </div>
                    </Link>
                  </div>
                </div> */}

                {/* <div className="col-xl-6 col-lg-6">
                  <div className="single-leading-feature">
                    <Link
                      to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[3].slug
                      }
                    >
                      <div className="thumb">
                        <div className="slf-overlay" />
                        <Img
                          fluid={{
                            ...data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[3].proprieta
                              .immagine.localFile.childImageSharp.fluid,
                            sizes: "(max-width: 512px) 80vw",
                          }}
                          alt={imagealt}
                        />
                      </div>

                      <div className="details">
                        <span>
                          {
                            data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[3].proprieta
                              .citta[0].title
                          }
                        </span>
                        <h4 className="title readeal-top">
                          {
                            data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[3].title
                          }
                        </h4>
                        <span
                          className="tipologia"
                          dangerouslySetInnerHTML={{
                            __html: `${data.allWpPage.nodes[0].proposteInEvidenza.propostaGrande.propostaGrande[3].proprieta.tipologia}`,
                          }}
                        ></span>
                      </div>
                    </Link>
                  </div>
                </div> */}





                {/* test 1  */}
                <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                <div className="single-feature">
                  <Link to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[1].slug
                      }>
                    <div className="thumb">

                    {/* test */}
                    {(function () {
                        if (data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[1].proprieta.etichetta ) {
                          return (
                            <span className="etichetta etichetta-list etichetta-list-small"
                              dangerouslySetInnerHTML={{
                                __html: `${data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[1].proprieta.etichetta}`,
                              }}
                            ></span>
                          )
                        } else {
                          return ""
                        }
                      })()}

                      {(function () {
                        if (data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[1].proprieta.venduta ) {
                          return (
                            <span className="etichetta etichetta-list etichetta-list-small venduta"
                              dangerouslySetInnerHTML={{
                                __html: `${data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[1].proprieta.venduta}`,
                              }}
                            ></span>
                          )
                        } else {
                          return ""
                        }
                      })()}
                      {/* /test */}

                      <Img
                        fluid={{
                            ...data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[1].proprieta
                              .immagine.localFile.childImageSharp.fluid,
                            sizes: "(max-width: 512px) 80vw",
                          }}
                        alt={imagealt}
                      />
                    </div>
                  </Link>
                  <div className="details">
                    <a href="/" className="feature-logo">
                      <img src={logoProposte} alt={imagealt} />
                    </a>
                    <p className="author">{
                            data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[1].proprieta
                              .citta[0].title
                          }</p>
                    <h6 className="title readeal-top">
                      <Link to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[1].slug
                      }>{
                            data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[1].title
                          }</Link>
                    </h6>

                    da Euro{" "}
                    <h6 className="price"> {data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[1].proprieta.prezzo}</h6>

                    <ul className="contact-list">
                      <li>
                        <a
                          className="phone"
                          href="tel:+393391745031"
                          aria-label="icon-phone"
                        >
                          <i className="fa fa-phone" />
                        </a>
                      </li>
                      <li>
                        <a
                          className="message"
                          href="mailto:info@sognocasaliguria.it"
                          aria-label="icon-mail"
                        >
                          <i className="fa fa-envelope" />
                        </a>
                      </li>
                      <li className="readeal-top dettagli-btn">
                        <Link className="btn btn-yellow" to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[1].slug
                      }>
                          Dettagli
                        </Link>
                      </li>
                      <li className="arrow-mobile">
                        <Link
                          className="arrow"
                          aria-label="icon-arrow"
                          to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[1].slug
                      }
                        >
                          <i className="fa fa-share" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
                {/* test 1  */}

                {/* test 2 */}
                <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                <div className="single-feature">
                  <Link to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[2].slug
                      }>
                    <div className="thumb">

                    {/* test */}
                    {(function () {
                        if (data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[2].proprieta.etichetta ) {
                          return (
                            <span className="etichetta etichetta-list etichetta-list-small"
                              dangerouslySetInnerHTML={{
                                __html: `${data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[2].proprieta.etichetta}`,
                              }}
                            ></span>
                          )
                        } else {
                          return ""
                        }
                      })()}

                      {(function () {
                        if (data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[2].proprieta.venduta ) {
                          return (
                            <span className="etichetta etichetta-list etichetta-list-small venduta"
                              dangerouslySetInnerHTML={{
                                __html: `${data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[2].proprieta.venduta}`,
                              }}
                            ></span>
                          )
                        } else {
                          return ""
                        }
                      })()}
                      {/* /test */}

                      <Img
                        fluid={{
                            ...data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[2].proprieta
                              .immagine.localFile.childImageSharp.fluid,
                            sizes: "(max-width: 512px) 80vw",
                          }}
                        alt={imagealt}
                      />
                    </div>
                  </Link>
                  <div className="details">
                    <a href="/" className="feature-logo">
                      <img src={logoProposte} alt={imagealt} />
                    </a>
                    <p className="author">{
                            data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[2].proprieta
                              .citta[0].title
                          }</p>
                    <h6 className="title readeal-top">
                      <Link to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[2].slug
                      }>{
                            data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[2].title
                          }</Link>
                    </h6>

                    da Euro{" "}
                    <h6 className="price"> {data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[2].proprieta.prezzo}</h6>

                    <ul className="contact-list">
                      <li>
                        <a
                          className="phone"
                          href="tel:+393391745031"
                          aria-label="icon-phone"
                        >
                          <i className="fa fa-phone" />
                        </a>
                      </li>
                      <li>
                        <a
                          className="message"
                          href="mailto:info@sognocasaliguria.it"
                          aria-label="icon-mail"
                        >
                          <i className="fa fa-envelope" />
                        </a>
                      </li>
                      <li className="readeal-top dettagli-btn">
                        <Link className="btn btn-yellow" to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[2].slug
                      }>
                          Dettagli
                        </Link>
                      </li>
                      <li className="arrow-mobile">
                        <Link
                          className="arrow"
                          aria-label="icon-arrow"
                          to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[2].slug
                      }
                        >
                          <i className="fa fa-share" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
                {/* test 2 */}

                {/* test 3 */}
                <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                <div className="single-feature">
                  <Link to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[3].slug
                      }>
                    <div className="thumb">

                    {/* test */}
                    {(function () {
                        if (data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[3].proprieta.etichetta ) {
                          return (
                            <span className="etichetta etichetta-list etichetta-list-small"
                              dangerouslySetInnerHTML={{
                                __html: `${data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[3].proprieta.etichetta}`,
                              }}
                            ></span>
                          )
                        } else {
                          return ""
                        }
                      })()}

                      {(function () {
                        if (data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[3].proprieta.venduta ) {
                          return (
                            <span className="etichetta etichetta-list etichetta-list-small venduta"
                              dangerouslySetInnerHTML={{
                                __html: `${data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[3].proprieta.venduta}`,
                              }}
                            ></span>
                          )
                        } else {
                          return ""
                        }
                      })()}
                      {/* /test */}

                      <Img
                        fluid={{
                            ...data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[3].proprieta
                              .immagine.localFile.childImageSharp.fluid,
                            sizes: "(max-width: 512px) 80vw",
                          }}
                        alt={imagealt}
                      />
                    </div>
                  </Link>
                  <div className="details">
                    <a href="/" className="feature-logo">
                      <img src={logoProposte} alt={imagealt} />
                    </a>
                    <p className="author">{
                            data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[3].proprieta
                              .citta[0].title
                          }</p>
                    <h6 className="title readeal-top">
                      <Link to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[3].slug
                      }>{
                            data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[3].title
                          }</Link>
                    </h6>

                    da Euro{" "}
                    <h6 className="price"> {data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[3].proprieta.prezzo}</h6>

                    <ul className="contact-list">
                      <li>
                        <a
                          className="phone"
                          href="tel:+393391745031"
                          aria-label="icon-phone"
                        >
                          <i className="fa fa-phone" />
                        </a>
                      </li>
                      <li>
                        <a
                          className="message"
                          href="mailto:info@sognocasaliguria.it"
                          aria-label="icon-mail"
                        >
                          <i className="fa fa-envelope" />
                        </a>
                      </li>
                      <li className="readeal-top dettagli-btn">
                        <Link className="btn btn-yellow" to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[3].slug
                      }>
                          Dettagli
                        </Link>
                      </li>
                      <li className="arrow-mobile">
                        <Link
                          className="arrow"
                          aria-label="icon-arrow"
                          to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[3].slug
                      }
                        >
                          <i className="fa fa-share" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
                {/* test 3 */}

                {/* test 4 */}
                <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                <div className="single-feature">
                  <Link to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[4].slug
                      }>
                    <div className="thumb">

                    {/* test */}
                    {(function () {
                        if (data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[4].proprieta.etichetta ) {
                          return (
                            <span className="etichetta etichetta-list etichetta-list-small"
                              dangerouslySetInnerHTML={{
                                __html: `${data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[4].proprieta.etichetta}`,
                              }}
                            ></span>
                          )
                        } else {
                          return ""
                        }
                      })()}

                      {(function () {
                        if (data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[4].proprieta.venduta ) {
                          return (
                            <span className="etichetta etichetta-list etichetta-list-small venduta"
                              dangerouslySetInnerHTML={{
                                __html: `${data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[4].proprieta.venduta}`,
                              }}
                            ></span>
                          )
                        } else {
                          return ""
                        }
                      })()}
                      {/* /test */}

                      <Img
                        fluid={{
                            ...data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[4].proprieta
                              .immagine.localFile.childImageSharp.fluid,
                            sizes: "(max-width: 512px) 80vw",
                          }}
                        alt={imagealt}
                      />
                    </div>
                  </Link>
                  <div className="details">
                    <a href="/" className="feature-logo">
                      <img src={logoProposte} alt={imagealt} />
                    </a>
                    <p className="author">{
                            data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[4].proprieta
                              .citta[0].title
                          }</p>
                    <h6 className="title readeal-top">
                      <Link to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[4].slug
                      }>{
                            data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[4].title
                          }</Link>
                    </h6>

                    da Euro{" "}
                    <h6 className="price"> {data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[4].proprieta.prezzo}</h6>

                    <ul className="contact-list">
                      <li>
                        <a
                          className="phone"
                          href="tel:+393391745031"
                          aria-label="icon-phone"
                        >
                          <i className="fa fa-phone" />
                        </a>
                      </li>
                      <li>
                        <a
                          className="message"
                          href="mailto:info@sognocasaliguria.it"
                          aria-label="icon-mail"
                        >
                          <i className="fa fa-envelope" />
                        </a>
                      </li>
                      <li className="readeal-top dettagli-btn">
                        <Link className="btn btn-yellow" to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[4].slug
                      }>
                          Dettagli
                        </Link>
                      </li>
                      <li className="arrow-mobile">
                        <Link
                          className="arrow"
                          aria-label="icon-arrow"
                          to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[4].slug
                      }
                        >
                          <i className="fa fa-share" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
                {/* test 4 */}

                {/* test 5 */}
                <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                <div className="single-feature">
                  <Link to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[5].slug
                      }>
                    <div className="thumb">

                    {/* test */}
                    {(function () {
                        if (data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[5].proprieta.etichetta ) {
                          return (
                            <span className="etichetta etichetta-list etichetta-list-small"
                              dangerouslySetInnerHTML={{
                                __html: `${data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[5].proprieta.etichetta}`,
                              }}
                            ></span>
                          )
                        } else {
                          return ""
                        }
                      })()}

                      {(function () {
                        if (data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[5].proprieta.venduta ) {
                          return (
                            <span className="etichetta etichetta-list etichetta-list-small venduta"
                              dangerouslySetInnerHTML={{
                                __html: `${data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[5].proprieta.venduta}`,
                              }}
                            ></span>
                          )
                        } else {
                          return ""
                        }
                      })()}
                      {/* /test */}

                      <Img
                        fluid={{
                            ...data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[5].proprieta
                              .immagine.localFile.childImageSharp.fluid,
                            sizes: "(max-width: 512px) 80vw",
                          }}
                        alt={imagealt}
                      />
                    </div>
                  </Link>
                  <div className="details">
                    <a href="/" className="feature-logo">
                      <img src={logoProposte} alt={imagealt} />
                    </a>
                    <p className="author">{
                            data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[5].proprieta
                              .citta[0].title
                          }</p>
                    <h6 className="title readeal-top">
                      <Link to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[5].slug
                      }>{
                            data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[5].title
                          }</Link>
                    </h6>

                    da Euro{" "}
                    <h6 className="price"> {data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[5].proprieta.prezzo}</h6>

                    <ul className="contact-list">
                      <li>
                        <a
                          className="phone"
                          href="tel:+393391745031"
                          aria-label="icon-phone"
                        >
                          <i className="fa fa-phone" />
                        </a>
                      </li>
                      <li>
                        <a
                          className="message"
                          href="mailto:info@sognocasaliguria.it"
                          aria-label="icon-mail"
                        >
                          <i className="fa fa-envelope" />
                        </a>
                      </li>
                      <li className="readeal-top dettagli-btn">
                        <Link className="btn btn-yellow" to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[5].slug
                      }>
                          Dettagli
                        </Link>
                      </li>
                      <li className="arrow-mobile">
                        <Link
                          className="arrow"
                          aria-label="icon-arrow"
                          to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[5].slug
                      }
                        >
                          <i className="fa fa-share" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
                {/* test 5 */}

                {/* test 6 */}
                <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                <div className="single-feature">
                  <Link to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[6].slug
                      }>
                    <div className="thumb">

                    {/* test */}
                    {(function () {
                        if (data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[6].proprieta.etichetta ) {
                          return (
                            <span className="etichetta etichetta-list etichetta-list-small"
                              dangerouslySetInnerHTML={{
                                __html: `${data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[6].proprieta.etichetta}`,
                              }}
                            ></span>
                          )
                        } else {
                          return ""
                        }
                      })()}

                      {(function () {
                        if (data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[6].proprieta.venduta ) {
                          return (
                            <span className="etichetta etichetta-list etichetta-list-small venduta"
                              dangerouslySetInnerHTML={{
                                __html: `${data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[6].proprieta.venduta}`,
                              }}
                            ></span>
                          )
                        } else {
                          return ""
                        }
                      })()}
                      {/* /test */}

                      <Img
                        fluid={{
                            ...data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[6].proprieta
                              .immagine.localFile.childImageSharp.fluid,
                            sizes: "(max-width: 512px) 80vw",
                          }}
                        alt={imagealt}
                      />
                    </div>
                  </Link>
                  <div className="details">
                    <a href="/" className="feature-logo">
                      <img src={logoProposte} alt={imagealt} />
                    </a>
                    <p className="author">{
                            data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[6].proprieta
                              .citta[0].title
                          }</p>
                    <h6 className="title readeal-top">
                      <Link to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[6].slug
                      }>{
                            data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[6].title
                          }</Link>
                    </h6>

                    da Euro{" "}
                    <h6 className="price"> {data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[6].proprieta.prezzo}</h6>

                    <ul className="contact-list">
                      <li>
                        <a
                          className="phone"
                          href="tel:+393391745031"
                          aria-label="icon-phone"
                        >
                          <i className="fa fa-phone" />
                        </a>
                      </li>
                      <li>
                        <a
                          className="message"
                          href="mailto:info@sognocasaliguria.it"
                          aria-label="icon-mail"
                        >
                          <i className="fa fa-envelope" />
                        </a>
                      </li>
                      <li className="readeal-top dettagli-btn">
                        <Link className="btn btn-yellow" to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[6].slug
                      }>
                          Dettagli
                        </Link>
                      </li>
                      <li className="arrow-mobile">
                        <Link
                          className="arrow"
                          aria-label="icon-arrow"
                          to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[6].slug
                      }
                        >
                          <i className="fa fa-share" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
                {/* test 6 */}





                {/* <div className="col-xl-6 col-lg-6">
                  <div className="single-leading-feature">
                    <Link
                      to={
                        data.allWpPage.nodes[0].proposteInEvidenza
                          .propostaGrande.propostaGrande[2].slug
                      }
                    >
                      <div className="slf-overlay" />
                      <div className="thumb">
                        <Img
                          fluid={{
                            ...data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[2].proprieta
                              .immagine.localFile.childImageSharp.fluid,
                            sizes: "(max-width: 512px) 80vw",
                          }}
                          alt={imagealt}
                        />
                      </div>
                      <div className="details">
                        <span>
                          {
                            data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[2].proprieta
                              .citta[0].title
                          }
                        </span>
                        <h4 className="title readeal-top">
                          {
                            data.allWpPage.nodes[0].proposteInEvidenza
                              .propostaGrande.propostaGrande[2].title
                          }
                        </h4>
                        <span
                          className="tipologia"
                          dangerouslySetInnerHTML={{
                            __html: `${data.allWpPage.nodes[0].proposteInEvidenza.propostaGrande.propostaGrande[2].proprieta.tipologia}`,
                          }}
                        ></span>
                      </div>
                    </Link>
                  </div>
                </div> */}

                {/* {data.allWpPage.nodes[0].proposteInEvidenza.proposteInEvidenza.proposteInEvidenza.map(
                  (node, i) => (
                    <div key={i} className="col-lg-3 col-md-6 col-sm-6 col-6">
                      <div className="single-feature">
                        <Link to={node.slug}>
                          <div className="thumb">
                            <Img
                              fluid={{
                                ...node.appartamento.immagine.localFile
                                  .childImageSharp.fluid,
                                sizes: "(max-width: 512px) 80vw",
                              }}
                              alt={imagealt}
                            />
                          </div>
                        </Link>
                        <div className="details">
                          <span className="feature-logo">
                            <img src={logoProposte} alt={imagealt} />
                          </span>
                          <p className="author">
                            {node.appartamento.citta[0].title}
                          </p>
                          <h6 className="title readeal-top">
                            <Link to={node.slug}>{node.title}</Link>
                          </h6>
                          da Euro{" "}
                          <h6 className="price"> {node.appartamento.prezzo}</h6>
                          <ul className="info-list">
                            {(function () {
                              if (node.appartamento.extra === true) {
                                return (
                                  <li>
                                    <i className="fa fa-car" /> Possibilità
                                    posto auto
                                  </li>
                                )
                              } else {
                                return ""
                              }
                            })()}
                          </ul>
                          <ul className="contact-list">
                            <li>
                              <a
                                className="phone"
                                href="tel:+393391745031"
                                aria-label="icon-phone"
                              >
                                <i className="fa fa-phone" />
                              </a>
                            </li>
                            <li>
                              <a
                                className="message"
                                href="mailto:info@sognocasaliguria.it"
                                aria-label="icon-mail"
                              >
                                <i className="fa fa-envelope" />
                              </a>
                            </li>
                            <li className="readeal-top dettagli-btn">
                              <Link className="btn btn-yellow" to={node.slug}>
                                Dettagli
                              </Link>
                            </li>
                            <li className="arrow-mobile">
                              <Link
                                className="arrow"
                                aria-label="icon-arrow"
                                to={node.slug}
                              >
                                <i className="fa fa-share" />
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )
                )} */}
              </div>
            </div>
          </div>
        </>
      )}
    />
  )
}
